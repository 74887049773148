import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { useParams } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Spinner from '../components/spinner/Spinner';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const TestResult = () => {
    let { userId, levelNumber } = useParams();
    const [status, setStatus] = useState();
    const [name, setName] = useState('');
    const [result, setResult] = useState({});
    const [isTestPassed, setIsTestPassed] = useState({});
    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        }
    });

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers,
            redirect: "follow"
        };
        fetch(`${process.env.REACT_APP_API_URL}/test-table/${userId}/${levelNumber}/`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setStatus('success');
                setName(data.name);
                setResult(JSON.parse(data.results));
                setIsTestPassed(data.is_test_passed);
            })
            .catch((error) => {
                setStatus('error')
            });
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
        <div className="test-result">
            {status === 'success' ? (
            <>
                <Box sx={{ mt: 3, mb: 3, color: '#BCE2EE' }}>
                    <h4>{name}</h4>
                    <h4>Результаты теста №{levelNumber}</h4>
                </Box>
                <TableContainer component={Paper} sx={{ maxWidth: 200, ml: 0 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Номер вопроса</TableCell>
                            <TableCell align="right">Ответ ученика</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Object.entries(result).map(([number, { answer, is_correct }]) => (
                            <TableRow
                                key={number}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {number}
                            </TableCell>
                            <TableCell align="right" sx={{ bgcolor: is_correct ? '#276b4a' : '#701212' }}>{answer}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paper elevation={3} sx={{ 
                        mt: 3, 
                        bgcolor: isTestPassed ? '#276b4a' : '#701212', 
                        color: '#ffffff',
                        p: 2,
                        pb: 1,
                        width: 'fit-content'
                    }}>
                    <h6>Результат: <b>Тест { isTestPassed ? 'сдан' : 'не сдан'}</b></h6>                    
                </Paper>
            </>
            ) : status === 'error' ? <p>Ошибка загрузки данных</p> : 
            status === 'pending' ? (
                <div>
                    <Spinner />
                </div>
            ) : ''}
        </div>
        </ThemeProvider>
    )
};
export default TestResult;