import { useEffect, useState } from 'react';
import certificate from '../../assets/images/level1/certificate.png';
import certificate2 from '../../assets/images/level2/certificate.png';
import certificate3 from '../../assets/images/level3/certificate.png';

const Certificate = ({firstName, lastName, color, levelNumber}) => {
    const [desktop, setDesktop] = useState(window.innerWidth > 500);
    const [width, setWidth] = useState(desktop ? 500 : 300);
    const [height, setHeight] = useState(desktop ? 700 : 410);
    useEffect(() => {
        const colors = {
            white: '#FFFFFF',
            black: '#000000'
        }
        function draw() {
            var canvas = document.getElementById("canvas");
            var ctx = document.getElementById("canvas").getContext("2d");
            var image = new Image();
            const fsize = desktop ? '47' : '26';
            ctx.font = `${fsize}px verdana, sans-serif`;
            image.onload=function() {
                ctx.drawImage(image,0,0,canvas.width,canvas.height);
                ctx.fillStyle = colors[color];
                const w = desktop ? 110 : 65;
                const h1 = desktop ? 390 : 210;
                const h2 = desktop ? 440 : 240;
                ctx.fillText(`${lastName}`, w, h1);
                ctx.fillText(`${firstName}`, w, h2);
            };
            image.src = `/media/levels/level-${levelNumber}/certificate/certificate.png`;
          }
          draw();
    }, [])

    const download = () => {
        var link = document.createElement('a');
        link.download = 'certificate.png';
        link.href = document.getElementById('canvas').toDataURL()
        link.click();
    }
    return (
        <div className='test__certificate'>
            <canvas id="canvas" width={width} height={height}></canvas>
            <button className='test__certificate-btn' onClick={download}>Скачать сертификат</button>
        </div>
    );
}

export default Certificate;

