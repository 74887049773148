import { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import LoginForm from '../components/loginForm/LoginForm';
import LevelsView from '../components/levelsView/LevelsView';
import Spinner from "../components/spinner/Spinner";

const cookies = new Cookies();

const Levels = () => {
    const [content, setContent] = useState(<></>);

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("X-CSRFToken", cookies.get('csrftoken'));
        const requestOptions = {
            method: "GET",
            headers,
            redirect: "follow"
        };
        setContent(<Spinner />)
        fetch(`${process.env.REACT_APP_API_URL}/is-authenticated`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setContent(data.authenticated ? <LevelsView /> : <LoginForm />)
            })
            .catch((error) => {
                console.log({error})
                setContent(<LoginForm />)
            });
    }, []);
    
    return (
    <div className="test-wrapper">
        {content}
    </div>
    );
}

export default Levels;