import { Link } from "react-scroll";
import { useLocation } from 'react-router-dom';
import Modal from '../modal/Modal';
import logo from '../../assets/images/logo.png';
import './header.scss';

const Header = () => {
  const location = useLocation();
  const links = [
    {
      href: 'about',
      text: 'О школе'
    }, 
    {
      href: 'services',
      text: 'Услуги'
    },
    {
      href: 'contacts',
      text: 'Контакты'
    }
  ]
  return (
    <header className="header">
      <div className="header__nav">
        <div className="header__left">
          <a href="/">
            <img className="header__logo" src={logo} alt="Логотип" />
          </a>
          <a href="/levels" className="header__button header__levels">Познать го</a>
        </div>
        <div className='header__links'>
          {links.map((link) => {
            if (location.pathname === '/') {
              return <Link
                key={link.href}
                to={link.href}
                className='header__link'
                spy={true}
                smooth={true}
                offset={-130}
                duration={100}>
                {link.text}
              </Link>
            } else {
              return <a key={link.href} href={`/#${link.href}`} className='header__link'>{link.text}</a>
            }
          })}
          <a href="/levels" className="header__button header__levels header__levels-mobile">Познать го</a>
          <Modal buttonLabel='Хочу записаться!' buttonClass='header__button header__button-order' />
        </div>
      </div>
    </header>
  );
}

export default Header;