import './reviews.scss';

const Reviews = () => {
    return (
        <section className="section reviews">
            <h2>Отзывы</h2>
            <div className="reviews__blocks">
                <div className="reviews__block reviews__block_1">
                    <h3>Надежда</h3>
                    <p>Доброго времени суток! Хочу выразить искреннюю признательность и благодарность руководителю и учителю Го-клуба «Звезда» Александру Старых! Спасибо Вам за знания, которые станут фундаментом в дальнейшем успешном обучении, за то сопереживание, содействие, доброту и чуткость, которые проявляете к ученикам. Благодарю от всего сердца за уникальный подход к преподаванию Го и за веру в нас, ваших учеников!</p>
                </div>
                <div className="reviews__block reviews__block_2">
                    <h3>Артём</h3>
                    <p>Замечательный клуб! Счастливая находка для нас! Сын захотел научиться играть в Го. Пришли в клуб. Я думал, что сходит на пару занятий и остынет. Но занятия настолько увлекательные, что ребенок ещё больше увлекся! Очень рекомендую клуб и занятия Го - очень полезная игра для развития мозга.
                    </p>
                </div>
                <div className="reviews__block reviews__block_3">
                    <h3>Юлия</h3>
                    <p>Очень нравятся занятия с Александром, находит подход к человеку, понятно всë объясняет, приводит примеры сравнения жизни и игры, это очень интересно) Игра в го раскрывает наш характер, наши привычки, Александр тоже обращает на это внимание &#128578; <br /> Однозначно рекомендую занятия с Александром, с ним интересно, просто, доступно и очень увлекательно играть в го)</p>
                </div>
                <div className="reviews__block reviews__block_3">
                    <h3>Марьяна</h3>
                    <p>Обучалась у Александра основам игры Го. Хочу отметить индивидуальный подход и доступное объяснение правил, техники игры. Александр на примерах, в том числе из жизни, объясняет тактику и стратегию Го и этап за этапом ведет к пониманию смысла и глубины игрового процесса. Обучение проходит интересно и увлекает в загадочный мир игры древнего Китая.</p>
                </div>
            </div>
        </section>
    );
}

export default Reviews;