import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Cookies } from 'react-cookie';
import LoginForm from '../components/loginForm/LoginForm';
import LevelView from '../components/levelsView/LevelView';

const cookies = new Cookies();

const Level = () => {
    /**
     * Компонент уровня
     */

    let { levelNumber } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState();
    const [isAuth, setIsAuth] = useState();

    useEffect(() => {
        /**
         * Проверка: если пользователь зарегестрирован, то ему дается доступ к материалам
         */
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('X-CSRFToken', cookies.get('csrftoken'));
        const requestOptions = {
            method: 'GET',
            headers,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_API_URL}/is-authenticated`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setStatus('success');
                setIsAuth(data.authenticated);
            })
            .catch((error) => {
                setStatus('error');
            });
    }, []);

    useEffect(() => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const requestOptions = {
            method: 'GET',
            headers,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_API_URL}/available-levels`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                const levels = data.levels;
                if (!levels[levelNumber]) {
                    navigate('/levels');
                }
            })
    }, []);

    const content = (status, isAuth) => {
        if (status === 'error') {
            return <p>Ошибка</p>
        } else if (status === 'pending') {
            return <p>Загрузка...</p>
        } else {
            return isAuth ? <LevelView levelNumber={levelNumber} /> : <LoginForm />
        }
    }
    
    return (
    <div className="test-wrapper">
        {content(status, isAuth)}
    </div>
    );
}

export default Level;