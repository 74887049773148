import { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import Certificate from './Certificate';

const cookies = new Cookies();

const Test = ({isTestPassed, setTestFinished, testContent, levelNumber}) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState();
    const [certificateStatus, setCertificateStatus] = useState();
    const [certificate, setCertificate] = useState(<></>);
    const [testStatus, setTestStatus] = useState(isTestPassed);
    const [empty, setEmpty] = useState(new Set());
    const [value, setValue] = useState({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        27: 0,
        28: 0,
        29: 0,
        30: 0,
    });
    useEffect(() => {
        if (isTestPassed || certificateStatus) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            const requestOptions = {
                method: "GET",
                headers,
                redirect: "follow"
            };
            fetch(`${process.env.REACT_APP_API_URL}/certificate-data`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    setTestFinished(true);
                    setCertificate(<Certificate 
                                        lastName={data.last_name} 
                                        firstName={data.first_name} 
                                        color={testContent.certificate_text_color}
                                        levelNumber={levelNumber}>
                                    </Certificate>);
                })
        }
    }, [isTestPassed, certificateStatus])

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        }
    });
    
    const handleChange = (event, num) => {
        const val = value
        setValue({...val, [num]: Number(event.target.value)});
        empty.delete(num);
    };

    const finish = async (value) => {
        const set = new Set()
        for (let [number, answer] of Object.entries(value)) {
            if (!answer) {
                set.add(number)
            }
        }
        setEmpty(set);
        if (!set.size) {
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("X-CSRFToken", cookies.get('csrftoken'));
            const raw = JSON.stringify({
                level: levelNumber, 
                test: value
            })
            const requestOptions = {
                method: "POST",
                headers: headers,
                body: raw,
                redirect: "follow"
            };
            await fetch(`${process.env.REACT_APP_API_URL}/test`, requestOptions) 
                .then((response) => response.json())
                .then((data) => {
                    setStatus('success');
                    setTestStatus(data.result);
                    setTestFinished(true);

                    if (data.result) {
                        const userData = {id: data.id, app_key: data.app_key}
                        const headersPMG = new Headers();
                        headersPMG.append("Content-Type", "application/json");
                        const rawPMG = JSON.stringify(userData);
                        const requestOptionsPMG = {
                            method: "POST",
                            headers: headersPMG,
                            body: rawPMG,
                            redirect: "follow"
                        };
                        fetch("https://playmorego.org/api/v1/test/finished", requestOptionsPMG) 
                            .then((response) => response.json())
                            .then((data) => {
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setStatus('error');
                });
        }
    }

    return (
        <ThemeProvider theme={darkTheme}>
        <div className='test__testview'>
            {!status && !isTestPassed ? (
                <>
                <CardMedia
                    className='test__video-media'
                    component="video"
                    image={`/media/levels/level-${levelNumber}/video/${testContent.video_url}`}
                    title="Видеоурок"
                    autoPlay
                    controls
                    />
                {testContent.video_tasks ? 
                (<a href={`/media/levels/level-${levelNumber}/video-materials/materials.pdf`} target='_blank' className='test__video-lessons'>Задания к видео</a>)
                : '' } 
                <FormControl>
                <h2 className='test__header-text'>Пройди тест:</h2>
                <div className="test__row">
                    {Object.entries(testContent.answers_quantity).map(([number, count]) => {
                        return (
                            <div key={number} className="test__item">
                                <img src={`/media/levels/level-${levelNumber}/test/${number}.png`} />
                                <div className="test__radio">
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value[number]}
                                        onChange={(e) => handleChange(e, number)}>
                                        {Array(count).fill(0).map((_, i) => <FormControlLabel key={i} value={i+1} control={<Radio />} label={i+1} />)}
                                    </RadioGroup>
                                </div>
                                {empty.has(number) ? <p className='test__empty'>Не выбран вариант ответа</p> : ''}
                            </div> 
                        )
                    })}
                </div>
                <button className='test__button' onClick={() => finish(value)}>Завершить</button>
                {empty.size ? <p className='test__empty test__empty_main'>Ответьте на все вопросы, чтобы завершить тест</p> : ''}
            </FormControl></>)
            : status === 'success' && !certificateStatus ?
                testStatus ? (<div className='test__result-img'>
                    <img src={`/media/levels/level-${levelNumber}/other/success.png`} onClick={() => setCertificateStatus(true)} />
                </div>)
                : (<div className='test__result-img'>
                    <img src={`/media/levels/level-${levelNumber}/other/not-success.png`} onClick={() => navigate('/levels')} />
                </div>)
            : ''}
            {certificate}
        </div>
        </ThemeProvider>
    );
}

export default Test;