import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import parse from 'html-react-parser';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import AddIcon from '@mui/icons-material/Add';
import Test from './Test'
import logoImg from '../../assets/images/test-img-logo.png';
import defaultImg from '../../assets/images/test-img-default.svg';
import downloadImg from '../../assets/images/test-img-download.svg';
import t1 from '../../assets/docs/1.pdf';
import t2 from '../../assets/docs/2.pdf';
import t3 from '../../assets/docs/3.pdf';
import t4 from '../../assets/docs/4.pdf';
import t5 from '../../assets/docs/5.pdf';
import t6 from '../../assets/docs/6.pdf';
import t7 from '../../assets/docs/7.pdf';
import t8 from '../../assets/docs/8.pdf';
import t9 from '../../assets/docs/9.pdf';
import t10 from '../../assets/docs/10.pdf';
import t11 from '../../assets/docs/11.pdf';
import t12 from '../../assets/docs/12.pdf';
import t13 from '../../assets/docs/13.pdf';
import t14 from '../../assets/docs/14.pdf';
import t15 from '../../assets/docs/15.pdf';
import t16 from '../../assets/docs/16.pdf';
import t17 from '../../assets/docs/17.pdf';
import t18 from '../../assets/docs/18.pdf';
import t19 from '../../assets/docs/19.pdf';
import t20 from '../../assets/docs/20.pdf';
import t21 from '../../assets/docs/21.pdf';
import t22 from '../../assets/docs/22.pdf';
import t23 from '../../assets/docs/23.pdf';
import t24 from '../../assets/docs/24.pdf';
import testVideo from '../../assets/images/level1/test-video.png';

const LevelView = ({ levelNumber }) => {
    const [testFinished, setTestFinished] = useState(false);
    const [status, setStatus] = useState();
    const [content, setContent] = useState(<></>);
    const [materialsContent, setMaterialsContent] = useState(<></>);
    const [innerTestContent, setInnerTestContent] = useState({});
    const [testContent, setTestContent] = useState(<div className="test__video"><img src={`/media/levels/level-${levelNumber}/other/not-video.png`} /></div>);

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers,
            redirect: "follow"
        };
        fetch(`${process.env.REACT_APP_API_URL}/available-materials/${levelNumber}/`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setStatus('success');
                setInnerTestContent(data.test_content);
                setContent(formatContent(status, data.materials, data.test, data.is_test_passed, data.test_content, data.teacher_files));
            })
            .catch((error) => {
                setStatus('error')
            });
    }, []);

    const formatContent = (status, materials, test, isTestPassed, innerTestContent, teacherFiles) => {
        if (status === 'error') {
            setContent(<p>Ошибка загрузки материалов</p>)
        } else if (status === 'pending') {
            setContent(<p>Загрузка...</p>)
        } else {
            const links = {
                row1: [
                    `/media/levels/level-${levelNumber}/materials/1.pdf`,
                    `/media/levels/level-${levelNumber}/materials/2.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/3.pdf`,
                    `/media/levels/level-${levelNumber}/materials/4.pdf`,
                    `/media/levels/level-${levelNumber}/materials/5.pdf`,
                    `/media/levels/level-${levelNumber}/materials/6.pdf`
                ],
                row2: [
                    `/media/levels/level-${levelNumber}/materials/7.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/8.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/9.pdf`,
                    `/media/levels/level-${levelNumber}/materials/10.pdf`,
                    `/media/levels/level-${levelNumber}/materials/11.pdf`,
                    `/media/levels/level-${levelNumber}/materials/12.pdf`
                ],
                row3: [
                    `/media/levels/level-${levelNumber}/materials/13.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/14.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/15.pdf`,
                    `/media/levels/level-${levelNumber}/materials/16.pdf`,
                    `/media/levels/level-${levelNumber}/materials/17.pdf`,
                    `/media/levels/level-${levelNumber}/materials/18.pdf`
                ],
                row4: [
                    `/media/levels/level-${levelNumber}/materials/19.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/20.pdf`, 
                    `/media/levels/level-${levelNumber}/materials/21.pdf`,
                    `/media/levels/level-${levelNumber}/materials/22.pdf`,
                    `/media/levels/level-${levelNumber}/materials/23.pdf`,
                    `/media/levels/level-${levelNumber}/materials/24.pdf`
                ]
            }
            const result = {row1: [], row2: [], row3: [], row4: []};
            let row = 1;
            for (let i = 1; i <= 24; i++) {
                const item = {download: materials.includes(i)};
                result[`row${row}`].push(item);
                if (i % 6 === 0) {
                    row++;
                }
            }
            const materialsContent = ['row1', 'row2', 'row3', 'row4'].map((row, index) => {
                let link = 'https://playmorego.org/club/leningradskii-go-klub'
                if (teacherFiles) {
                    link = teacherFiles[index + 1];
                }
                return (
                    <div className="test__tasks-row" key={row}>
                        <a href={link} target='_blank' className='test__tasks-item test__logo'><img src={logoImg} alt="Логотип" /></a>
                        {result[row].map((item, i) => {
                            const active = <a key={i} href={links[row][i]} className='test__tasks-item' target='_blank'><img src={downloadImg} alt="Скачать" /></a>
                            const notActive = <div key={i} className='test__tasks-item'><img src={defaultImg} /></div>
                            return item.download ? active : notActive
                        })}
                    </div>
                )
            })
            setMaterialsContent(materialsContent);
            if (test) {
                setTestContent(<Test
                                    isTestPassed={isTestPassed} 
                                    setTestFinished={setTestFinished} 
                                    testContent={innerTestContent}
                                    levelNumber={levelNumber}>
                                </Test>);
            }
        }
    }

    return (
        <div className='test__levelview'>
            {!testFinished ?
            (<><Accordion className='test__accordion' sx={{
                backgroundColor: '#222222', 
                fontFamily: 'Montserrat, sans-serif', 
                padding: 0,
                borderRadius: '3px',
                'marginBottom': '30px'}}>
                    <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header">
                        <Typography>Немного о го</Typography>
                    </AccordionSummary>
                <AccordionDetails>
                <div className='test__md-editor'>
                    {Object.keys(innerTestContent).length > 0 ? parse(innerTestContent.text.replace(/style=\"height:\d+px; width:\d+px\"/g, '')) : ''}
                </div>
                </AccordionDetails>
            </Accordion>
            <h1 className='test__title'>
                Мы рекомендуем скачивать все задания, распечатывать и выставлять на доску. Помни, что по истечении месяца курс закроется.
                <br /><br />
                Играй со всеми, до кого дотянешься. И обязательно посещай своего куратора в го-школе для выяснения любого вопроса по игре!
            </h1>
            <div className='test__tasks'>
                {materialsContent}
            </div>
            </>)
            : ''}
            {testContent}
            {content}
        </div>
    );
}

export default LevelView;