import { useState, useEffect } from 'react';
import { redirect } from "react-router-dom";
import './test.scss';

const TestView = () => {
    const [status, setStatus] = useState('success');
    const [content, setContent] = useState(<></>);
    const [isTeacher, setIsTeacher] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers,
            redirect: "follow"
        };
        fetch(`${process.env.REACT_APP_API_URL}/available-levels`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setStatus('success');
                setContent(formatContent(status, data.levels))
                setIsTeacher(data.is_teacher)
                setId(data.user_id)
            })
            .catch((error) => {
                setStatus('error')
            });
    }, []);

    const logout = async () => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers,
            redirect: "follow"
        };
        await fetch(`${process.env.REACT_APP_API_URL}/logout`, requestOptions)
        window.location.reload()
    }

    const formatContent = (status, levels) => {
        if (status === 'error') {
            return <p className='load'>Ошибка загрузки материалов</p>
        } else if (status === 'pending') {
            return <p className='load'>Загрузка...</p>
        } else {
            return Object.entries(levels).map(([key, value], i) => {
                const active = <a key={i} className='test__level' href={`/level/${key}`}><img src={`/media/levels/level-${i + 1}/other/cover.png`} /></a>
                const notActive = <div key={i} className='test__level test__level-not-active'><img src={`/media/levels/level-${i + 1}/other/cover.png`} /></div>
                return value ? active : notActive
            })
        }
    }

    return (
        <>
            {isTeacher ? (<h1 className='test__teacher-header'>Аккаунт преподавателя</h1>) : ''}
            <a href={`https://t.me/TestPmgBot?start=${id}`} target="_blank" className='test__start-btn'>Запустить Гобота</a>
            <div className="test__levels">
                {content}
                <button className='test__logout' onClick={logout}>Выйти из аккаунта</button>
            </div>
        </>
    );
}

export default TestView;